<template>
  <v-card>
    <v-card-title
      class="text-truncate text-uppercase font-weight-semibold"
    >
      Mes Comptes
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-spacer></v-spacer>
      <v-row
        v-for="(item, itemIndex) in myAccounts"
        :key="item.code"
      >
        <v-col
          cols="12"
          sm="5"
        >
          <span class="d-inline-block font-weight-semibold py-2 px-5 text-uppercase text--primary">{{ item.name }}</span>
        </v-col>
        <v-col
          cols="12"
          sm="7"
          class="text-sm-left text-center"
        >
          <span
            v-for="(account, accountValueIndex) in item.accounts"
            :key="account.valeur"
            class="d-block py-2 px-5"
          >
            <span v-if="!account.wantToModif" class='text-primary'>{{ account.valeur }}</span>

            <v-text-field
              v-else
              v-model="account.valeur"
              dense
              :rules='LocalNameRules'
              outlined
              placeholder="Numéro de compte"
              label="Numéro de compte"
              hide-details="auto"
              autofocus
            >
            </v-text-field>

            <v-btn
              v-if="!account.wantToModif"
              x-small
              color="primary"
              icon
              class="ml-2 text--primary"
              @click="editAccount(itemIndex,accountValueIndex)"
            >
              <v-icon>
                {{ icon.mdiPencilOutline }}
              </v-icon>
            </v-btn>

            <v-btn
              v-else
              x-small
              icon
              color="success"
              @click="validEditingAccount(itemIndex,accountValueIndex)"
            >
              <v-icon>
                {{ icon.mdiCheck }}
              </v-icon>
            </v-btn>

            <v-btn
              x-small
              icon
              color="error"
              class="ml-1"
              @click="removeAccount(itemIndex,accountValueIndex)"
            >

              <v-icon>
                {{ icon.mdiCloseCircle }}
              </v-icon>
            </v-btn>

          </span>
        </v-col>
        <v-spacer></v-spacer>

        <hr/>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { mdiCheck, mdiCloseCircle, mdiPencilOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Http from '@/helpers/http'
import Vue from 'vue'

export default {
  name: 'MyAccountList',
  setup() {


    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const myAccounts = ref([])

    const meansOfpayments = ref([])

    const loadAccountByPaymentMethod = () => {
      Http.get('get-payment-account')
        .then(response => {
          const data = Object.entries(response.accountByPaymode)
          data.forEach(i => {
            const meansName = meansOfpayments.value.filter(item => item.code === i[0])
            console.log('meansName')
            console.log(meansName)
            if (meansName.length) {
              const comptes = []
              i[1].forEach(account => {
                comptes.push({
                  valeur: account,
                  wantToModif: false,
                })
              })
              myAccounts.value.push({
                code: i[0],
                name: meansName[0].label,
                accounts: comptes,
              })
            }
          })

          // myAccounts.value = Object.entries(response.accountByPaymodeOfCustomer)

          console.log('get-payment-by-account')
          console.log(myAccounts.value)
        })
        .catch(error => error)
    }

    const loadMeansOfPayment = () => {
      Http.get('get-paymode')
        .then(response => {
          meansOfpayments.value = response.data
          console.log('meansOfpayments')
          console.log(response.data)
          loadAccountByPaymentMethod()
        })
        .catch(error => error)
    }

    const editAccount = (itemIdx, accountIndex) => {
      myAccounts.value[itemIdx].accounts[accountIndex].wantToModif = true
    }

    const validEditingAccount = (itemIdx, accountIndex) => {
      myAccounts.value[itemIdx].accounts[accountIndex].wantToModif = false
      console.log(itemIdx, accountIndex)
    }

    const removeAccount = (itemIdx, accountIndex) => {
      const countAccount = myAccounts.value[itemIdx].accounts.length
      if (countAccount === 1) {
        myAccounts.value.splice(itemIdx, 1)
      } else {
        myAccounts.value[itemIdx].accounts.splice(accountIndex, 1)
      }
    }

    loadMeansOfPayment()

    return {
      LocalNameRules,
      myAccounts,

      editAccount,
      validEditingAccount,
      removeAccount,

      icon: {
        mdiCloseCircle,
        mdiPencilOutline,
        mdiCheck,
      },
    }
  },
}
</script>

<style scoped>

</style>
